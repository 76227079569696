<template>
  <div class="info-item">
    <div class="title">
      {{ title }}
    </div>
    <div
      v-if="!isShow"
      class="info-slot"
    >
      <slot name="show" />
    </div>
    <div
      v-if="isShow"
      class="info-slot"
    >
      <slot name="hide" />
    </div>
    <div
      v-if="needEdit"
      class="btn"
    >
      <span
        v-if="!isShow"
        class="edit-btn"
        @click="handleEdite"
      >修改</span>
      <template v-if="isShow">
        <span
          class="sure-btn"
          @click="confirm"
        >确认</span>
        <span
          class="cancel-btn"
          @click="cancel"
        >取消</span>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: ''
      },
      needEdit: {
        type: Boolean,
        default: true
      }
    },
    data() {
      return {
        isShow: false
      }
    },
    methods: {
      handleEdite() {
        this.isShow = true
        this.$emit('edite')
      },
      confirm() {
        this.$emit('confirm')
      },
      cancel() {
        this.$emit('cancel')
      },
      close() {
        this.isShow = !this.isShow
      }
    },
  }
</script>

<style lang="less" scoped>
.info-item {
  width: 100%;
  min-height: 42px;
  display: flex;
  align-items: center;
  margin-top: 26px;
  .title {
    width: 145px;
    font-size: 16px;
    color: #A5A5A5;
  }
  .info-slot {
    width: 360px;
    height: 100%;
    margin-left: 76px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #25293D;
  }
  .btn {
    margin-left: 30px;
    &> span {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
    }
    .edit-btn {
      border: 1px solid #E0E2E8;
      border-radius: 2px;
      background: #F9F9F9;
      width: 60px;
      height: 30px;
      color: #25293D;
      font-size: 14px;
      box-sizing: border-box;
    }
    .sure-btn {
      width: 60px;
      height: 30px;
      background: #DEB87B;
      border-radius: 2px;
      color: #FFFFFF;
      box-sizing: border-box;
    }
    .cancel-btn {
      color: #CE9B63;
      margin-left: 28px;
    }
  }
}
</style>