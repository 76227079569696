<template>
  <div class="app-wrap">
    <h3>个人信息</h3>
    <div class="avator-wrap">
      <img :src="userInfo.avatar">
      <span
        class="ava-btn"
        @click="handleSelectPIC"
      >更改头像</span>
    </div>
    <!-- <div class="content-wrap">
      <h3>证件照片</h3>
      <div class="id_tip">
        上传身份证照片
      </div>
      <div class="card-upload">
        <div class="img-wrap">
          <img src="@/assets/images/myFavors/idbg.png">
        </div>
        <img src="@/assets/images/myFavors/idDemo.png">
      </div>
      <div class="upload-btn">
        <span class="btn">上传证件</span>
        <span class="tips"><span>*</span>&ensp;&ensp;请按照拍摄规范上传身份证正、反面</span>
      </div>
    </div> -->

    <div class="personal-wrap">
      <h3>个人信息</h3>
      <div class="personal-item">
        <div>姓名</div>
        <span>{{ userInfo.clientName }}</span>
      </div>
      <div class="personal-item">
        <div>性别</div>
        <span>{{ userInfo.clientGender === '1' ? '女' : '男' }}</span>
      </div>
      <div class="personal-item">
        <div>年龄</div>
        <span>{{ getAge(userInfo.birthday) }}</span>
      </div>
      <div class="personal-item">
        <div>证件类型</div>
        <span>{{ idCardType }}</span>
      </div>
      <div class="personal-item">
        <div>证件号码</div>
        <span>{{ 
          userInfo.idNo ? userInfo.idNo.slice(0, 4) + '************' + userInfo.idNo.slice(-3) : ''
        }}</span>
      </div>
      <div class="personal-item">
        <div>证件有效期</div>
        <span>{{ 
          userInfo.idEnddate ? userInfo.idEnddate == '99991231' ?  '长期有效'
                                                                : userInfo.idEnddate.slice(0, 4) + '-' + userInfo.idEnddate.slice(4, 6) + '-' + userInfo.idEnddate.slice(-2)
                             : ''
        }}</span>
        <em style="cursor: pointer;" v-if="userInfo.addDataFlag !== '0'" @click="handleOpenDialog">修改</em>
      </div>
      <div class="personal-item">
        <div>证件照</div>
        <span :class="userInfo.photoFlag === '1' ? 'blue' : ''">{{ userInfo.photoFlag === '1' ? '已上传' : '未上传' }}</span>
      </div>
      <div class="personal-item">
        <div>国籍</div>
        <span>{{ nationalText }}</span>
      </div>
    </div>
    <template v-if="userInfo.addDataFlag !== '0'">
      <div class="comm-wrap">
        <h3>通讯信息</h3>
        <infoItem
          ref="zipCode"
          title="邮编"
          @confirm="zipcodeConfirm('zipCode')"
          @cancel="cancel('zipCode')"
        >
          <template #show>
            {{ userInfo.zipcode }}
          </template>
          <template #hide>
            <el-input
              v-model="userInfo.zipcode"
              maxlength="6"
            />
          </template>
        </infoItem>
        <infoItem
          ref="idAddress"
          title="地址"
          @confirm="addrConfirm('idAddress')"
          @cancel="cancel('idAddress')"
        >
          <template #show>
            {{ userInfo.idAddress }}
          </template>
          <template #hide>
            <el-input v-model="userInfo.idAddress" placeholder="请输入" />
          </template>
        </infoItem>
        <!-- <div class="adddr-wrap">
          <span>地址1</span>
          <div
            v-show="!addrShow"
            style="width:360px !important;margin-left: 76px;margin-right:30px"
          >
            {{ userInfo.homeAddrPrivince + userInfo.homeAddrCity + userInfo.homeAddrDistrict + userInfo.homeAddress }}
          </div>
          <div
            v-show="addrShow"
            style="width:360px !important;margin-left: 76px;margin-right:30px"
          >
            <el-cascader
              v-model="userInfo.homeAddrCode"
              class="pw-inpput"
              :props="{
                label: 'name',
                value: 'code'
              }"
              :options="chinaRegions"
              placeholder="选择省市区"
              @change="addrChange"
            />
            <el-input
              v-model="userInfo.homeAddress"
              placeholder="详细地址"
              style="margin-top:6px"
              maxlength="6"
            />
          </div>
          <em
            v-if="!addrShow"
            class="edit-btn"
            @click="addrEdit"
          >修改</em>
          <template v-if="addrShow">
            <em
              class="sure-btn"
              @click="addrSure"
            >确认</em>
            <em
              class="cancel-btn"
              @click="addrCancel"
            >取消</em>
          </template>
        </div> -->
        <infoItem
          ref="email"
          title="邮箱"
          @confirm="emailConfirm('email')"
          @cancel="cancel('email')"
        >
          <template #show>
            {{ userInfo.email }}
          </template>
          <template #hide>
            <el-input v-model="userInfo.email" />
          </template>
        </infoItem>
      </div>
      <div class="comm-wrap">
        <h3>补充信息</h3>
        <infoItem
          ref="ofund"
          title="职业类别"
          @confirm="ofundConfirm('ofund')"
          @cancel="cancel('ofund')"
        >
          <template #show>
            {{ jobText }}
          </template>
          <template #hide>
            <el-select
              v-model="userInfo.ofundProfCode"
              style="width:100%"
            >
              <el-option
                v-for="item in jobDics"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              />
            </el-select>
          </template>
        </infoItem>
        <infoItem
          ref="income"
          title="年收入"
          @confirm="incomeConfirm('income')"
          @cancel="cancel('income')"
        >
          <template #show>
            {{ incomeText }}
          </template>
          <template #hide>
            <el-select
              v-model="userInfo.income"
              style="width:100%"
            >
              <el-option
                v-for="item in incomeDics"
                :key="item.dictValue"
                :label="item.dictLabel"
                :value="item.dictValue"
              />
            </el-select>
          </template>
        </infoItem>
        <infoItem
          ref="tax"
          title="税收居民身份声明"
          @confirm="taxConfirm('tax')"
          @cancel="cancel('tax')"
        >
          <template #show>
            {{ displayTaxTxt }}
          </template>
          <template #hide>
            <el-select
              v-model="userInfo.taxIdentity"
              style="width:100%"
            >
              <el-option
                v-for="item in taxDics"
                :key="item.value"
                :label="item.text"
                :value="item.value"
              />
            </el-select>
          </template>
        </infoItem>
        <infoItem
          ref="bene"
          title="账户实际受益人"
          @edite="beneEdite"
          @confirm="beneConfirm('bene')"
          @cancel="beneCancel('bene')"
        >
          <template #show>
            {{ displayBene }}
          </template>
          <template #hide>
            <zh-radio
              v-model="userInfo.beneficiaryType"
              label="1"
              @change="beneChange"
            >
              本人
            </zh-radio>
            <zh-radio
              v-model="userInfo.beneficiaryType"
              label="2"
              @change="beneChange"
            >
              他人
            </zh-radio>
          </template>
        </infoItem>


        <div
          v-show="otherBeneShow"
          class="bene-wrap"
        >
          <h4>实际受益人信息补齐</h4>
          <el-form
            ref="otherBene"
            :model="userInfo"
            :rules="otherRules"
            label-position="top"
            :disabled="beneDisabled"
          >
            <el-form-item
              class="card-item"
              label="姓名"
              prop="beneficiary"
            >
              <el-input
                v-model="userInfo.beneficiary"
                class="pw-inpput"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="性别"
              prop="beneficiaryGender"
            >
              <el-select
                v-model="userInfo.beneficiaryGender"
                class="pw-inpput"
              >
                <el-option
                  value="0"
                  label="男"
                />
                <el-option
                  value="1"
                  label="女"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="card-item"
              label="年龄（自动计算）"
            >
              <el-input
                disabled
                :value="getAge1(userInfo.beneficiaryBirthday)"
                class="pw-inpput"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="证件类型"
              prop="beneficiaryIdType"
            >
              <el-select
                v-model="userInfo.beneficiaryIdType"
                class="pw-inpput"
              >
                <el-option
                  v-for="item in sysIdType"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="card-item"
              label="证件号码"
              prop="beneficiaryIdno"
            >
              <el-input
                v-model="userInfo.beneficiaryIdno"
                class="pw-inpput"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="证件有效期"
              prop="beneficiaryIdValidate"
            >
              <el-date-picker
                v-model="userInfo.beneficiaryIdValidate"
                value-format="yyyyMMdd"
                format="yyyy.MM.dd"
                class="pw-inpput"
                :clearable="false"
                type="date"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="出生日期"
              prop="beneficiaryBirthday"
            >
              <el-date-picker
                v-model="userInfo.beneficiaryBirthday"
                value-format="yyyyMMdd"
                format="yyyy.MM.dd"
                class="pw-inpput"
                :clearable="false"
                type="date"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="国籍"
              prop="beneficiaryNationality"
            >
              <el-select
                v-model="userInfo.beneficiaryNationality"
                class="pw-inpput"
              >
                <el-option
                  v-for="item in nationalDics"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="card-item"
              label="职业类别"
              prop="beneficiaryProfCode"
            >
              <el-select
                v-model="userInfo.beneficiaryProfCode"
                class="pw-inpput"
              >
                <el-option
                  v-for="item in jobDics"
                  :key="item.dictValue"
                  :label="item.dictLabel"
                  :value="item.dictValue"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              class="card-item"
              label="电话"
              prop="beneficiaryTel"
            >
              <el-input
                v-model="userInfo.beneficiaryTel"
                class="pw-inpput"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="邮箱"
              prop="beneficiaryEMail"
            >
              <el-input
                v-model="userInfo.beneficiaryEMail"
                class="pw-inpput"
              />
            </el-form-item>
            <el-form-item
              class="card-item"
              label="邮编"
              prop="beneficiaryZipcode"
            >
              <el-input
                v-model="userInfo.beneficiaryZipcode"
                class="pw-inpput"
              />
            </el-form-item>
            <!-- <el-form-item
              class="card-item"
              label="所在地区"
              prop="beneficiaryAddrCode"
            >
              <el-cascader
                v-model="userInfo.beneficiaryAddrCode"
                class="pw-inpput"
                :props="{
                  label: 'name',
                  value: 'code'
                }"
                :options="chinaRegions"
                @change="handleChange"
              />
            </el-form-item> -->
            <el-form-item
              class="card-item"
              label="地址"
              prop="beneficiaryAddress"
            >
              <el-input
                v-model="userInfo.beneficiaryAddress"
                class="pw-inpput"
              />
            </el-form-item>
          </el-form>
        </div>
      </div>
    </template>
    

    <!-- 个人资料补充跳转 -->
    <span class="comm-btn" @click="$router.push({name: 'PersonalDataSupply'})" v-if="userInfo.addDataFlag === '0'">个人资料补充</span>

    <zh-dialog
      ref="dialog"
      title="证件有效期"
      width="667"
      height="437"
    >
      <div class="content-wrap">
        <h3>更新有效期请上传二代身份证</h3>
        <div class="card-upload">
          <div
            v-show="idcardUrl.length === 0"
            class="img-wrap"
          >
            <img src="@/assets/images/myFavors/idbg.png">
          </div>
          <div
            v-for="(item, index) in idcardUrl"
            :key="index"
            class="img-wrap"
          >
            <img :src="item">
            <div
              class="img-mask"
              @click="handleRemoveImg(index)"
            >
              <i class="iconfont icon-form-idcard-error" />
            </div>
          </div>
          <img src="@/assets/images/myFavors/idDemo.png">
        </div>
        <div class="upload-btn">
          <span
            class="btn"
            @click="uploadIdCard"
          >上传证件</span>
          <span class="tips"><span>*</span>&ensp;&ensp;<em>请按照拍摄规范依次上传身份证正面(人像面)、身份证反面(国徽面)，若顺序错误可能导致识别失误</em></span>
        </div>
        <div class="close-btn">
          <span @click="handleUpadateIdcard">确认</span>
        </div>
      </div>
    </zh-dialog>
  </div>
</template>

<script>
  import {mapGetters} from 'vuex'
  const CHINA_REGIONS = require('../../../assets/regions.json')
  import infoItem from './component/infoItem.vue'
  import { uploadFile } from '@/api/commom'
  import { popFileSelector } from '@/utils/debounce'
  import { updataAvatar, updataZipcode, updateEmail, updataOfound, updataIncome, residentStatus, updateUserBeneficiary, ocrIdCard, upLoadIDCard, updateAddress } from '@/api/myAcc'
  import { zipCodeCheck, emailCheck, phoneCheck, idCardCheck } from '@/utils/checkReg.js'
  function getArr(arr) {
    const s = []
    arr.forEach(item => {
      s.push({
        code: item.code,
        name: item.name
      })
      if (item.children) {
        s.push(...getArr(item.children))
      }
    })
    return s
  }
  const REGIONS_ARR = getArr(CHINA_REGIONS)
  const REGIONS_MAP = {}
  REGIONS_ARR.forEach(item => {
    REGIONS_MAP[item.code] = item.name
  })
  export default {
    components: {
      infoItem
    },
    data() {
      return {
        beneDisabled: true,
        addrShow: false,
        idcardUrl: [],
        otherBeneShow: false,
        // 省市区
        chinaRegions: CHINA_REGIONS,
        taxDics:[
					{
						value:'0',
						text: '仅为中国税收居民'
					},
					{
						value:'1',
						text: '仅为非中国税收居民'
					},
					{
						value:'2',
						text: '既是中国税收居民也是其他国家（地区）'
					},
				],
        // 证件类型字典
				sysIdType: [],
				// 国籍字典
				nationalDics: [],
				// 职业类别
				jobDics: [],
				// 年收入字典
				incomeDics: [],
        userInfo: {},
        value: '',
        otherRules: {
          beneficiary: [
            { required: true, message: '请输入姓名', trigger: 'blur' }
          ],
          beneficiaryGender: [
            { required: true, message: '请选择性别', trigger: 'blur' }
          ],
          beneficiaryIdType: [
            { required: true, message: '请选择证件类别', trigger: 'blur' }
          ],
          beneficiaryIdno: [
            { required: true, message: '请输入证件号码', trigger: 'blur' },
            { pattern: idCardCheck, message: '证件号码格式不正确' }
          ],
          beneficiaryIdValidate: [
            { required: true, message: '请选择证件有效期', trigger: 'blur' }
          ],
          beneficiaryBirthday: [
            { required: true, message: '请选择出生日期', trigger: 'blur' }
          ],
          beneficiaryNationality: [
            { required: true, message: '请选择国籍', trigger: 'blur' }
          ],
          beneficiaryProfCode: [
            { required: true, message: '请选择职业类别', trigger: 'blur' }
          ],
          beneficiaryTel: [
            { required: true, message: '请输入电话号码', trigger: 'blur' },
            { pattern: phoneCheck, message: '电话号码格式不正确' }
          ],
          beneficiaryEMail: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
            { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
          ],
          beneficiaryZipcode: [
            { required: true, message: '请输入邮编', trigger: 'blur' },
            { pattern: zipCodeCheck, message: '邮编格式不正确' }
          ],
          // beneficiaryAddrCode: [
          //   { required: true, message: '请选择省市区', trigger: 'blur' }
          // ],
          beneficiaryAddress: [
            { required: true, message: '请输入地址', trigger: 'blur' }
          ]
        }
      }
    },
    computed: {
      ...mapGetters(["mobile"]),
      idCardType() {
				return (this.sysIdType.find(item => item.dictValue === this.userInfo.idKindGb) || {}).dictLabel
			},
			nationalText() {
				return (this.nationalDics.find(item => item.dictValue === this.userInfo.fundNationality) || {}).dictLabel
			},
			jobText() {
				return (this.jobDics.find(item => item.dictValue === this.userInfo.ofundProfCode) || {}).dictLabel
			},
			incomeText() {
				return (this.incomeDics.find(item => +item.dictValue === +this.userInfo.income) || {}).dictLabel
			},
      displayBene() {
        if (this.userInfo.beneficiaryType == '') return ''
        return this.userInfo.beneficiaryType === '1' ? '本人' : '他人'
      },
      displayTaxTxt() {
        return (this.taxDics.find(item => item.value === this.userInfo.taxIdentity) || {}).text
      }
    },
    created () {
      const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      this.$set(this, 'userInfo', userInfo)
      this.getDicts('sys_id_type').then(res => {
				this.sysIdType = res.data
			})
			this.getDicts('sys_user_nationality').then(res => {
				this.nationalDics = res.data
			})
			this.getDicts('sys_user_job_type').then(res => {
				this.jobDics = res.data
			})
			this.getDicts('income_type').then(res => {
				this.incomeDics = res.data.map(item => {
          return {
            ...item,
            dictValue: +item.dictValue
          }
        })
			})
      if (this.userInfo.beneficiaryType == '2') {
        this.otherBeneShow = true
      }
    },
    methods: {
      addrConfirm(ref) {
        if (this.idAddress === '') {
          this.$message({
            message: '地址不能为空',
            type: 'warning'
          });
					return
        }
        updateAddress({
					idAddress: this.userInfo.idAddress,
				}).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '地址修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      // addrChange(val) {
      //   this.userInfo.homeAddrPrivince = REGIONS_MAP[val[0]]
      //   this.userInfo.homeAddrCity = REGIONS_MAP[val[1]]
      //   this.userInfo.homeAddrDistrict = REGIONS_MAP[val[2]]
      //   this.userInfo.homeAddrCode = val[2]
      // },
      
      // addrEdit() {
      //   this.addrShow = true
      // },
      // addrSure() {
      //   if (this.homeAddress === '' || this.userInfo.homeAddrCode == '') {
      //     this.$message({
      //       message: '地址不能为空',
      //       type: 'warning'
      //     });
			// 		return
      //   }
      //   updateAddress({
			// 		homeAddrPrivince: this.userInfo.homeAddrPrivince,
			// 		homeAddrCity: this.userInfo.homeAddrCity,
			// 		homeAddrDistrict: this.userInfo.homeAddrDistrict,
			// 		homeAddrCode: this.userInfo.homeAddrCode,
			// 		homeAddress: this.userInfo.homeAddress,
			// 	}).then(() => {
      //     this.$store.dispatch('GetInfo').then(() => {
      //       this.$message({
      //         message: '地址修改成功',
      //         type: 'success'
      //       });
      //       const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      //       this.$set(this, 'userInfo', userInfo)
      //       this.addrShow = false
      //     })
      //   })
        
      // },
      // addrCancel() {
      //   this.$store.dispatch('GetInfo').then(() => {
      //     const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
      //     this.$set(this, 'userInfo', userInfo)
      //     this.addrShow = false
      //   })
      // },

      handleRemoveImg(i) {
        this.idcardUrl.splice(i, 1)
      },
      uploadIdCard() {
        if (this.idcardUrl.length >= 2) {
          return
        }
        popFileSelector().then(file => {
          const fd = new FormData()
          fd.append('file', file)
          fd.append('folder', '/img/ocr/'+this.mobile+'/')
          uploadFile(fd).then(res => {
            this.idcardUrl.push(res.data)
          })
        })
      },
      handleOpenDialog() {
        this.$refs['dialog'].open()
      },
      async handleUpadateIdcard() {
        if (this.idcardUrl.length !== 2) {
          this.$message({
            message: '请规范上传身份证',
            type: 'warning'
          });
					return
        }
        
        // 识别身份证正面
				const imgAInfo = JSON.parse((await ocrIdCard({
					imgUrl: this.idcardUrl[0],
          // outImg: '1'
				})).data)
				// 识别身份证背面
				const imgBInfo = JSON.parse((await ocrIdCard({
					imgUrl: this.idcardUrl[1],
          // outImg: '1'
				})).data)
        const { BIRTHDAY, NUM, SEX, NAME } = imgAInfo
				const { PERIOD } = imgBInfo
        if (
					NUM === '' ||
					SEX === '' ||
					NAME === '' ||
					BIRTHDAY ==='' ||
					PERIOD === ''
				) {
          this.$message({
            message: '身份证基本信息未识别成功，请正确上传身份证',
            type: 'warning',
          })
					return
				}
        const reg = /^(\d{4})(\d{2})(\d{2})$/
        // 校验有效期格式是否正确
        const idEnddate = PERIOD.split('-')[1] === '长期' ? '99991231' : PERIOD.split('-')[1].replace(/[.]/g, '')
        
        if (!(reg.test(idEnddate) && RegExp.$2<=12 && RegExp.$3<=31)) {
          this.$message({
            message: '身份证背面照片不规范，请重新上传',
            type: 'warning',
          })
          return
        }

        if (NUM !== this.userInfo.idNo) {
          this.$message({
            message: '请确认身份证是否为所属本人',
            type: 'warning'
          });
					return
				}
        upLoadIDCard({
          idNo: NUM,
          idEnddate: idEnddate,
          idPhotoPositive: this.idcardUrl[0],
          idPhotoNegative: this.idcardUrl[1]
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '有效期修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs['dialog'].close()
          })
        })
        // ocrIdCard({
        //   urlPaths: this.idcardUrl
        // }).then(({data}) => {
        //   upLoadIDCard({
        //     idEnddate: data[1].endDate,
        //     idPhotoPositive: this.idcardUrl[0],
        //     idPhotoNegative: this.idcardUrl[0]
        //   }).then(() => {
				// 		this.$store.dispatch('GetInfo').then(() => {
        //       this.$message({
        //         message: '有效期修改成功',
        //         type: 'success'
        //       });
        //       const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
        //       this.$set(this, 'userInfo', userInfo)
        //       this.$refs['dialog'].close()
        //     })
				// 	})
        // })
      },
      // handleChange(val) {
      //   this.userInfo.beneficiaryAddrPrivince = REGIONS_MAP[val[0]]
      //   this.userInfo.beneficiaryAddrCity = REGIONS_MAP[val[1]]
      //   this.userInfo.beneficiaryAddrDistrict = REGIONS_MAP[val[2]]
      //   this.userInfo.beneficiaryAddrCode = val[2]
      // },
      beneEdite() {
        if (+this.userInfo.beneficiaryType === 2) {
          this.otherBeneShow = true
        }
        this.beneDisabled = false
      },
      beneCancel() {
        this.$store.dispatch('GetInfo').then(() => {
          const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
          this.$set(this, 'userInfo', userInfo)
          this.$refs['bene'].close()
          console.log(+this.userInfo.beneficiaryType)
          if (+this.userInfo.beneficiaryType === 1) {
            this.otherBeneShow = false
          } else {
            this.otherBeneShow = true
          }
          this.beneDisabled = true
        })
      },
      beneChange(val) {
        if (val === '2') {
          this.otherBeneShow = true
        } else {
          this.otherBeneShow = false
        }
        this.userInfo = {
          ...this.userInfo,
          beneficiary: '',
          beneficiaryNationality: '',
          beneficiaryIdType: '',
          beneficiaryIdno: '',
          beneficiaryIdValidate: '',
          beneficiaryAddrCode: '',
          beneficiaryAddrPrivince: '',
          beneficiaryAddrCity: '',
          beneficiaryAddrDistrict: '',
          beneficiaryAddress: '',
          beneficiaryGender: '',
          beneficiaryBirthday: '',
          beneficiaryProfCode: '',
          beneficiaryTel: '',
          beneficiaryEMail: '',
          beneficiaryZipcode: ''
        } 
      },
      cancel(ref) {
        this.$store.dispatch('GetInfo').then(() => {
          const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
          this.$set(this, 'userInfo', userInfo)
          this.$refs[ref].close()
        })
      },
      zipcodeConfirm(ref) {
        if (!this.userInfo.zipcode) {
          this.$message({
            message: '邮编不能为空',
            type: 'warning'
          });
					return
				}
				if (!zipCodeCheck.test(this.userInfo.zipcode)) {
          this.$message({
            message: '邮编不合法',
            type: 'warning'
          });
					return
				}
        updataZipcode({
          zipcode: this.userInfo.zipcode
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '邮编修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      emailConfirm(ref) {
        if (!this.userInfo.email) {
          this.$message({
            message: '邮箱不能为空',
            type: 'warning'
          });
					return
				}
				if (!emailCheck.test(this.userInfo.email)) {
          this.$message({
            message: '邮箱不合法',
            type: 'warning'
          });
					return
				}
        updateEmail({
          email: this.userInfo.email
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '邮箱修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      ofundConfirm(ref) {
        if (this.userInfo.ofundProfCode === '') {
					this.$message({
            message: '职业类别不能为空',
            type: 'warning'
          });
					return
				}
				updataOfound({
          ofundProfCode: this.userInfo.ofundProfCode
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '职业类别修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      incomeConfirm(ref) {
        if (this.userInfo.income === '') {
					this.$message({
            message: '年收入不能为空',
            type: 'warning'
          });
					return
				}
				updataIncome({
          income: this.userInfo.income
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '年收入修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      taxConfirm(ref) {
        if (this.userInfo.taxIdentity === '') {
					this.$message({
            message: '税收居民身份声明不能为空',
            type: 'warning'
          });
					return
				}
				residentStatus({
          taxIdentity: this.userInfo.taxIdentity
        }).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            this.$message({
              message: '税收居民身份声明修改成功',
              type: 'success'
            });
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$refs[ref].close()
          })
        })
      },
      beneConfirm(ref) {
        if (this.userInfo.beneficiaryType === '1') {
          updateUserBeneficiary({
            beneficiaryType: '1'
          }).then(() => {
            this.$store.dispatch('GetInfo').then(() => {
              this.$message({
                message: '账户实际受益人修改成功',
                type: 'success'
              });
              const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
              this.$set(this, 'userInfo', userInfo)
              this.$refs[ref].close()
              this.beneDisabled = true
            })
          })
          return
        }
        this.$refs['otherBene'].validate((valid) => {
          if (valid) {
            const data = {
              beneficiary: this.userInfo.beneficiary,
              beneficiaryType: this.userInfo.beneficiaryType,
              beneficiaryNationality: this.userInfo.beneficiaryNationality,
              beneficiaryIdType: this.userInfo.beneficiaryIdType,
              beneficiaryIdno: this.userInfo.beneficiaryIdno,
              beneficiaryIdValidate: this.userInfo.beneficiaryIdValidate,
              // beneficiaryAddrCode: this.userInfo.beneficiaryAddrCode,
              // beneficiaryAddrPrivince: this.userInfo.beneficiaryAddrPrivince,
              // beneficiaryAddrCity: this.userInfo.beneficiaryAddrCity,
              // beneficiaryAddrDistrict: this.userInfo.beneficiaryAddrDistrict,
              beneficiaryAddress: this.userInfo.beneficiaryAddress,
              beneficiaryGender: this.userInfo.beneficiaryGender,
              beneficiaryBirthday: this.userInfo.beneficiaryBirthday,
              beneficiaryProfCode: this.userInfo.beneficiaryProfCode,
              beneficiaryTel: this.userInfo.beneficiaryTel,
              beneficiaryEMail: this.userInfo.beneficiaryEMail,
              beneficiaryZipcode: this.userInfo.beneficiaryZipcode
            }
            updateUserBeneficiary(data).then(() => {
              this.$store.dispatch('GetInfo').then(() => {
                this.$message({
                  message: '账户实际受益人修改成功',
                  type: 'success'
                });
                const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
                this.$set(this, 'userInfo', userInfo)
                this.$refs[ref].close()
                this.beneDisabled = true
              })
            })
          }
        })
        
      },
      getAge1(str) {
        console.log(str)
        var returnStr="0"
        if(str == ""|| str==null){
          returnStr = "0"
        }else{
          const _str = str.slice(0,4) + '-' + str.slice(4,6) + '-' + str.slice(-2)
          let workTime = new Date(_str.replace(/-/g, "/"));
          let d = new Date();
          let age = d.getFullYear() - workTime.getFullYear() - (d.getMonth() < workTime.getMonth() || (d.getMonth() == workTime.getMonth() && d.getDate() < workTime.getDate()) ? 1 : 0);
          returnStr = age + 1;
        }
        return returnStr;
			},
      getAge(str) {
        var returnStr="0"
        if(str == ""|| str==null){
          returnStr = "0"
        }else{
          let workTime = new Date(str.replace(/-/g, "/"));
          let d = new Date();
          let age = d.getFullYear() - workTime.getFullYear() - (d.getMonth() < workTime.getMonth() || (d.getMonth() == workTime.getMonth() && d.getDate() < workTime.getDate()) ? 1 : 0);
          returnStr = age + 1;
        }
        return returnStr;
			},
      handleSelectPIC() {
        popFileSelector().then(file => {
          const fd = new FormData()
          fd.append('file', file)
          fd.append('folder', '/img/header/'+this.mobile+'/')
          uploadFile(fd).then(res => {
            this.editeAvator(res.data)
          })
        })
      },
      editeAvator(url) {
        updataAvatar({avatar: url}).then(() => {
          this.$store.dispatch('GetInfo').then(() => {
            const userInfo = JSON.parse(localStorage.getItem('customerInfo')) || {}
            this.$set(this, 'userInfo', userInfo)
            this.$message({
              message: '头像修改成功',
              type: 'success'
            });
          })
				})
      }
    },
  }
</script>

<style lang="less" scoped>
@import url('@/assets/css/zc-common.less');
.app-wrap > h3 {
  width: 100%;
  font-weight: bold;
  font-size: 20px;
  color: #1F1F1F;
}

.avator-wrap {
  .content();
  .flex(flex-start, center, row);
  
  padding-bottom: 50px;
  &>img {
    width: 92px;
    height: 92px;
    border-radius: 50%;
  }
  .ava-btn {
    width: 124px;
    height: 30px;
    .inline-flex(center, center, row);
    background: #F9F9F9;
    border: 1px solid #E0E2E8;
    box-sizing: border-box;
    border-radius: 2px;
    font-size: 14px;
    color: #25293D;
    margin-left: 80px;
    cursor: pointer;
  }
}
.content-wrap {
  .content();
  margin-top: 0;
  padding: 0 30px;
  height: 100%;
  border-bottom: 0;
  &>h3 {
    margin-top: 14px;
    font-weight: normal;
    font-size: 14px;
    color: #BDC0CB;
  }
  .card-upload {
    .border-box();
    width: 100%;
    margin-top: 36px;
    .flex(flex-start, flex-start, row);
    .img-wrap {
      width: 174px;
      height: 112px;
      .flex(center, center, row);
      border: 1px solid #E0E2E8;
      position: relative;
      &>img {
        width: 162px;
        height: 100px;
      }
      .img-mask {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        position: absolute;
        top: 0;
        left: 0;
        background: #FFFFFF;
        opacity: 0.6;
        z-index: 1;
        &>i {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          color: #FFFFFF;
          width: 30px;
          height: 30px;
          background: rgba(0, 0, 0, 1);
        }
      }
    }
    &>img {
      width: 221.59px;
      height: 136.71px;
      margin-left: 20px;
    }
  }
  .upload-btn {
    width: 100%;
    margin-top: 20px;
    .flex(flex-start, center, row);
    .btn {
      .inline-flex(center, center, row);
      width: 174px;
      height: 40px;
      background: #FFFFFF;
      border: 1px solid #CE9B63;
      border-radius: 2px;
      font-size: 14px;
      color: #CE9B63;
    }
    .tips {
      font-size: 12px;
      color: #8691A8;
      display: inline-flex;
      align-items: center;
      &>span {
        margin-left: 14px;
        color: #B88141;
      }
      &>em {
        font-style: normal;
      }
    }
  }
  .close-btn {
    width: 100%;
    &>span {
      .btn(128px, 48px);
    }
    text-align: right;
    
    margin-top: 52px;
  }
}

.personal-wrap {
  .content();
  &>h3 {
    font-size: 18px;
    color: #1F1F1F;
    margin-bottom: 30px;
  }
  .personal-item {
    width: 100%;
    height: 16px;
    .flex(flex-start, center, row);
    margin-bottom: 30px;
    &>div {
      width: 145px;
      font-size: 16px;
      color: #A5A5A5;
    }
    &>span {
      display: inline-block;
      width: 360px;
      margin-left: 76px;
      font-size: 14px;
      color: #25293D;
      &.blue {
        color: #0366C4;
      }
    }
    &>em {
      font-style: normal;
      .inline-flex(center, center, row);
      width: 60px;
      height: 30px;
      background: #F9F9F9;
      border: 1px solid #E0E2E8;
      border-radius: 2px;
      color: #25293D;
      font-size: 14px;
      margin-left: 30px;
    }
  }
}

.comm-wrap {
  .content();
  &>h3 {
    font-size: 18px;
    color: #1F1F1F;
    margin-bottom: 30px;
  }
  .comm-item {
    display: flex;
    margin-top: 36px;
    // align-items: center;
    .title {
      width: 136px;
      font-size: 16px;
      color: #A5A5A5;
    }
    .wrap {
      display: flex;
      flex-wrap: wrap;
      margin-left: 76px;
    }
  }
}
.comm-btn{
  .inline-flex(center, center, row);
  width: 200px;
  height: 48px;
  margin-top: 44px;
  background: #FFFFFF;
  border: 1px solid #CE9B63;
  box-sizing: border-box;
  border-radius: 2px;
  font-size: 16px;
  color: #CE9B63;
  cursor: pointer;
}

.bene-wrap {
  margin-top: 32px;
  width: 620px;
  padding: 38px 70px 38px 30px;
  border: 2px dashed #E0E2E8;
  border-radius: 4px;
  background: #F8F7F7;
  &>h4 {
    font-weight: bold;
    font-size: 18px;
    color: #1F1F1F;
    padding-bottom: 24px;
  }
}
.adddr-wrap {
  width: 100%;
  min-height: 42px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  & > span {
    display: inline-block;
    width: 145px;
    font-size: 16px;
    color: #A5A5A5;
  }
}

.edit-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  border: 1px solid #E0E2E8;
  border-radius: 2px;
  background: #F9F9F9;
  width: 60px;
  height: 30px;
  color: #25293D;
  font-size: 14px;
  box-sizing: border-box;
}
.sure-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  width: 60px;
  height: 30px;
  background: #DEB87B;
  border-radius: 2px;
  color: #FFFFFF;
  box-sizing: border-box;
}
.cancel-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  color: #CE9B63;
  margin-left: 28px;
}
</style>
<style>
.pw-inpput {
  width:100% !important;
  height:48px !important
}
.pw-inpput .el-input__inner {
  height:48px !important;
  line-height: 48px !important;
}
.pw-inpput .el-input__suffix {
  right: 20px !important;
  display: flex;
  align-items: center;
}
.input-with-select .el-input-group__prepend {
  width: 65px !important;
}
.card-item .el-form-item__label{
  font-size: 14px !important;
  color: #25293D !important;
  padding: 0 !important;
}
.card-item .el-form-item__content {
  display: flex !important;
}
</style>